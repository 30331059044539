const shiftLog = [
  {
    path: '/shift-logs',
    name: 'shift-logs.list',
    component: () => import('@/modules/shiftlog/views/ShiftLogList.vue'),

  },
]

export default shiftLog
