const patient = [
  {
    path: '/patients',
    name: 'patients.list',
    component: () => import('@/modules/patient/views/PatientList.vue'),
    meta: {
      roles: ['super_admin', 'doctor', 'admin', 'analyst'],
    },

  },
]

export default patient
