const shift = [
  {
    path: '/shifts/:status',
    name: 'shifts.list',
    component: () => import('@/modules/shift/views/ShiftList.vue'),
    props: true,
    meta: {
      roles: ['super_admin', 'admin', 'doctor', 'analyst'],
    },

  },
  {
    path: '/shifts/:uuid/details',
    name: 'shifts.details',
    component: () => import('@/modules/shift/components/ShiftDetails.vue'),
    props: true,
    meta: {
      roles: ['super_admin', 'admin', 'doctor', 'analyst'],
    },

  },
]

export default shift
