const users = [
  {
    path: '/users',
    name: 'users.list',
    component: () => import('@/modules/users/views/UsersList.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import('@/modules/users/components/account-settings/AccountSettings.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'doctor'],
    },

  },
]

export default users
