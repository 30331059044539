const operator = [
  {
    path: '/operators',
    name: 'operators.list',
    component: () => import('@/modules/operator/views/OperatorList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },

  },
]

export default operator
