const tag = [
  {
    path: '/tags',
    name: 'tags.list',
    component: () => import('@/modules/tag/views/TagList.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },
  },
]

export default tag
