<template>
  <b-card md="12">
    <b-row>
      <b-col md="6">
        <b-button
          v-if="backButton"
          variant="flat-primary"
          class="btn-icon float-left"
          @click="onBack"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
        <h2>
          {{ name }}
        </h2>
      </b-col>
      <b-col md="6" class="text-right">
        <slot />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'PageHeader',
  components: {},
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    backButton: {
      type: Boolean,
      default: false,
      required: false,

    },
  },
  data: () => ({}),
  mounted() {},
  methods: {
    onBack() {
      this.$router.back()
    },
  },
}
</script>

<style scoped></style>
