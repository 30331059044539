import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import loader from './loader'
import auth from './auth/auth'
import team from './teams/team'
import hiringTypes from './hiring-type/hiring-type'
import paymentMethods from './payment-method'
import professionals from './professionals'
import status from './status/status'
import tags from './tags/tags'
import unity from './unities'
import timezones from './timezones/timezones'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    loader,
    auth,
    team,
    hiringTypes,
    paymentMethods,
    professionals,
    status,
    tags,
    unity,
    timezones,
  },
  strict: process.env.DEV,
})
