import { isUserLoggedIn, userRole } from '@/auth/utils'
import password from '@/modules/auth/routes/password'
import checkin from '@/modules/checkin/routes/checkin'
import checkinApproval from '@/modules/checkinapproval/routes/checkinapproval'
import clients from '@/modules/clients/routes/clients'
import scales from '@/modules/duty/routes/scales'
import hiringtype from '@/modules/hiringtype/routes/hiringtype'
import invite from '@/modules/invite/routes/invite'
import map from '@/modules/map/routes/map'
import professional from '@/modules/professionals/routes/professional'
import timemap from '@/modules/timemap/routes/timemap'
import operator from '@/modules/operator/routes/operator'
import patient from '@/modules/patient/routes/patient'
import paymentmethod from '@/modules/paymentmethod/routes/paymentmethod'
import person from '@/modules/person/routes/person'
import professionalsspecialty from '@/modules/professionalsspecialty/routes/professionalsspecialty'
import professionalunities from '@/modules/professionalunities/routes/professionalunities'
import reason from '@/modules/reason/routes/reason'
import shift from '@/modules/shift/routes/shift'
import shiftlog from '@/modules/shiftlog/routes/shiftlog'
import shiftsstatusapprovals from '@/modules/shiftsstatusapprovals/routes/shiftsstatusapprovals'
import specialty from '@/modules/specialty/routes/specialty'
import status from '@/modules/status/routes/status'
import tag from '@/modules/tag/routes/tag'
import team from '@/modules/team/routes/team'
import unity from '@/modules/unity/routes/unity'
import users from '@/modules/users/routes/users'
        // :routes-imports://

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/LoginCenter.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      roles: ['super_admin', 'admin', 'analyst'],
    },
  },
  {
    path: '/professional/register/:email',
    name: 'register.professional',
    component: () => import('@/modules/professionals/views/Register.vue'),
    props: true,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/professional/register/client/:token/:client',
    name: 'register.professional.client',
    component: () => import('@/modules/professionals/views/RegisterForClient.vue'),
    props: true,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/modules/error/views/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...routes,
    ...password,
    ...users,
    ...clients,
    ...unity,
    ...team,
    ...professional,
    ...map,
    ...timemap,
    ...person,
    ...professionalunities,
    ...specialty,
    ...professionalsspecialty,
    ...scales,
    ...shift,
    ...paymentmethod,
    ...hiringtype,
    ...operator,
    ...patient,
    ...status,
    ...shiftsstatusapprovals,
    ...checkin,
    ...reason,
    ...checkinApproval,
    ...shiftlog,
    ...tag,
    ...invite,
// :routes-unpack://
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'page-error-404'
        || to.name === 'login'
        || to.name === 'perdi-minha-senha'
        || to.name === 'recuperar-senha'
        || to.name === 'trocar-senha'
        || to.name === 'register.professional'
        || to.name === 'register.professional.client') {
    return next()
  }
  if (to.name && to.name.length) {
    if (!isUserLoggedIn() && to.name !== 'login') {
      router.push('/')
      return false
    }
  }

  const roles = to.meta.roles || []

  if (roles.length > 0 && !roles.find(item => item === userRole())) {
    router.push({ name: 'error-404' })
    return false
  }
  next()
  return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
