const state = {
  status: null,
}

const getters = {

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_STATUS(state, payload) {
    state.const = payload
  },
}

const actions = {
  getStatus: async ({ commit }) => {
    try {
      window.http.isLoading = false
      const data = await window.http.get('status')
      window.http.isLoading = true
      if (data.error) {
        return []
      }
      commit('SET_STATUS', data)
      return data
    } catch (error) {
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
